import server from 'api/server';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import InnerHtml from 'components/_common/InnerHtml/InnerHtml';

const DeleteDocumentsModal = ({ documents, open, onClose, onSuccess }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onDelete = async () => {
    try {
      setLoading(true);
      const {
        data: { safe, success, unsafeDocuments }
      } = await server.post(`/documents/delete-bulk`, {
        documentIds: documents
      });
      if (success) {
        onSuccess();
        onClose();
        setError('');
        setDisabled(false);
        setLoading(false);
        toast.info('Documents deleted successfully.');
      } else if (!safe) {
        setLoading(false);
        setDisabled(true);
        setError(
          `The following documents cannot be deleted: <br/>
          ${unsafeDocuments.map((d) => `<li>${d}</li>`)}
          Please remove them from workflows first.`
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.info('Something went wrong!');
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setDisabled(false);
        setError('');
      }}
      closeOnOverlayClick={false}
    >
      <div class="modal_top">
        <h3 class="title is-h3">Delete Document</h3>
        <div class="field">
          <p>
            This will permanently remove the selected documents. This action
            cannot be undone.
          </p>
          {error && (
            <InnerHtml classes="help is-danger" unsanitizedData={error} />
          )}
        </div>
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              id="delete-documents-modal-button-delete"
              disabled={disabled}
              onClick={onDelete}
              class={`button is-info is-small is-danger ${
                loading ? 'is-loading' : ''
              }`}
            >
              Delete Documents
            </button>
          </div>
          <div class="control">
            <button
              id="delete-documents-modal-button-cancel"
              onClick={() => {
                onClose();
                setDisabled(false);
                setError('');
              }}
              class="button is-info is-small is-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteDocumentsModal;
