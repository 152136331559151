import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { cloneDocument } from 'redux/actions/documentActions';

const DuplicateDocumentModal = ({ open, onClose, id, name }) => {
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(cloneDocument(id));
    toast.info('Duplicating and processing the document...');
    onClose();
  };
  return (
    <div class={`modal ${open && 'is-active'}`}>
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title is-small">Duplicate Document?</p>
        </header>
        <section class="modal-card-body" style={{ fontSize: '14px' }}>
          Are you sure you want to duplicate {name}?
        </section>
        <footer class="modal-card-foot">
          <button 
            id="duplicate-document-modal-button-cancel"
            class="button is-small" 
            onClick={(e) => onClose()}
          > 
            Cancel   
          </button>
          <button  
            id="duplicate-document-modal-button-duplicate"
            class="button is-small is-info" 
            onClick={onPress}
          >
            Duplicate
          </button>
        </footer>
      </div>
    </div>
  );
};
export default DuplicateDocumentModal;
