import React, { Fragment, useState } from 'react';

const TreeItem = ({
  label,
  id,
  isSelected,
  setSelected,
  disabled,
  children
}) => {
  const [isOpen, toggleItemOpen] = useState(null);

  return (
    <div>
      <nav
        class="level tree-item"
        style={{
          background: `${isSelected ? '#F0F6FC' : ''}`
        }}
      >
        <div class="level-left">
          <div className="label">
            <input
              id={"file-tree-checkBox-tree-item-" + id}
              class="tree-check"
              type="checkbox"
              checked={isSelected}
              disabled={disabled}
              onClick={(e) => {
                if (isSelected) {
                  setSelected(null);
                } else {
                  setSelected(id);
                }
              }}
            />
            {isOpen ? (
              <i class="fas fa-folder-open" />
            ) : (
              <i class="fas fa-folder" />
            )}{' '}
            {label}
          </div>
        </div>
        <div class="level-right">
          {children && children.length > 0 && (
            <div
              className="icon-container"
              onClick={() => toggleItemOpen(!isOpen)}
            >
              {isOpen ? (
                <i class="fas fa-chevron-up" aria-hidden="true"></i>
              ) : (
                <i class="fas fa-chevron-down" />
              )}
            </div>
          )}
        </div>
      </nav>
      <div class="tree-children">{isOpen && children}</div>
    </div>
  );
};

const FileTree = ({
  tree,
  selected,
  setSelected,
  disabledFn,
  showRoot,
  rootLabel
}) => {
  const createTree = (child) =>
    child.children && (
      <>
        <TreeItem
          id={child.data.id}
          key={child.data.id}
          setSelected={setSelected}
          isSelected={child.data.id == selected}
          label={child.data.name}
          disabled={disabledFn && disabledFn(child)}
        >
          {child.children.map((branch) => {
            return <Fragment key={child.id}>{createTree(branch)}</Fragment>;
          })}
        </TreeItem>
      </>
    );

  return (
    <div class="tree">
      <div>
        {showRoot && (
          <TreeItem
            id={'root'}
            setSelected={setSelected}
            isSelected={selected == 'root'}
            label={rootLabel || 'Home'}
            disabled={!!!showRoot}
          />
        )}
      </div>
      {tree && tree.map((child, i) => <div key={i}>{createTree(child)}</div>)}
    </div>
  );
};
export default FileTree;
