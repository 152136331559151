import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteDocumentModal from './DeleteDocumentModal';
import DeleteFolderModal from './DeleteFolderModal';
import DuplicateDocumentModal from './DuplicateDocumentModal';
import MoveModal from './MoveModal';
import RenameFolderModal from './RenameFolderModal';

const DropdownButton = ({
  data,
  onAction,
  folderTree,
  direction,
  siblingFolders
}) => {
  const [isActive, updateIsActive] = useState(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deleteFolderModal, setDeleteFolderModal] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [renameFolderModalOpen, setRenameFolderModalOpen] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => {
    updateIsActive(false);
  });

  return (
    <>
      <DeleteDocumentModal
        open={deleteDocumentModal}
        onClose={async () => {
          setDeleteDocumentModal(false);
          onAction();
        }}
        file={data}
      />

      <DeleteFolderModal
        open={deleteFolderModal}
        onClose={async () => {
          setDeleteFolderModal(false);
          onAction();
        }}
        file={data}
      />

      <RenameFolderModal
        open={renameFolderModalOpen}
        onClose={async () => {
          setRenameFolderModalOpen(false);
          onAction();
        }}
        file={data}
        siblingFolders={siblingFolders}
      />
      <MoveModal
        open={moveModalOpen}
        folderTree={folderTree}
        onClose={async () => {
          setMoveModalOpen(false);
          onAction();
        }}
        file={data}
      />

      <DuplicateDocumentModal
        open={duplicateModalOpen}
        onClose={async () => {
          setDuplicateModalOpen(false);
          onAction();
        }}
        id={data.id}
        name={data.name}
      />

      <div
        class={`dropdown is-right ${isActive ? 'is-active' : ''} ${
          direction === 'up' ? 'is-up' : ''
        }`}
      >
        <div class="dropdown-trigger">
          <button
            id="dropdown-button-ellipsis"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            className="button is-small"
            onClick={(e) => {
              updateIsActive(!isActive);
            }}
          >
            <i class="menu fas fa-ellipsis-h"></i>
          </button>
        </div>

        <div class="dropdown-menu" id="dropdown-menu" role="menu" ref={ref}>
          <div class="dropdown-content">
            {data.type === 'document' && (
              <Link 
                id="dropdown-menu-button-edit"
                to={`/documents/editor/${data.id}`} 
                class="dropdown-item"
              >
                <i class="far fa-edit"></i> Edit
              </Link>
            )}
            {data.type === 'document' && (
              <a
                id="dropdown-menu-button-duplicate"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setDuplicateModalOpen(true);
                  updateIsActive(!isActive);
                }}
                class="dropdown-item"
              >
                <i class="far fa-copy"></i> Duplicate
              </a>
            )}
            {data.type === 'folder' && (
              <a
                id="dropdown-menu-button-rename"
                class="dropdown-item"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setRenameFolderModalOpen(true);
                  updateIsActive(!isActive);
                }}
              >
                <i class="far fa-edit"></i> Rename
              </a>
            )}
            <a
              id="dropdown-menu-button-move"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setMoveModalOpen(true);
                updateIsActive(!isActive);
              }}
              class="dropdown-item"
            >
              <i class="fas fa-arrows-alt"></i> Move
            </a>
            <a
              id="dropdown-menu-button-delete"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (data.type == 'folder') {
                  setDeleteFolderModal(true);
                } else {
                  setDeleteDocumentModal(true);
                }
                updateIsActive(!isActive);
              }}
              class="dropdown-item delete-item"
            >
              <i class="far fa-trash-alt"></i> Delete
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DropdownButton;
